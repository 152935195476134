import React, { useEffect } from "react";
// import { Images } from "../../../../assets/assets";
import { FetchCourseCategories } from "../../../../redux/home/homeActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import Slider from "react-slick";
import "./Categories.css";

function Categories() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(FetchCourseCategories());
  }, [dispatch]);
  const categories = useSelector((state) => state?.home?.categoriesList?.data);
  const loading = useSelector((state) => state?.home?.loading);
  const settings = {
    dots: categories?.length < 2 ? false : true,
    infinite: categories?.length < 2 ? false : true,
    arrows: false,
    margin: 10,
    speed: 500,
    slidesToShow: categories?.length < 5 ? categories.length : 5, // Adjust dynamically
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: categories?.length < 4 ? categories.length : 4,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: categories?.length < 3 ? categories.length : 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: categories?.length < 2 ? categories.length : 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          className: "center",
          centerMode: true,
        },
      },
    ],
  };  
  return (
    <div className="popular-categories-wraper">
      {loading ? (
        <Loader />
      ) : (
        <div className="theme__container">
          <section className="categories-list">
            <div className="grid-container">
              {categories?.length > 0 ? (
                <Slider {...settings}>
                  {categories.slice(0, 5).map((category, index) => (
                    <div className="category-item" key={index}>
                      <div className="category-img-wraper">
                        <img
                          src={category.category_image}
                          className="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div className="category-naming">
                        <h5>{category.category_name}</h5>
                        <p>{category.total_course_count} Courses</p>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className="no-categories">
                  <span className="badge">No Categories Found</span>
                </div>
              )}
            </div>
          </section>
        </div>
      )}
    </div>
  );
}

export default Categories;
