import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faFileAlt, faVideo, faTimes } from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from 'react-player';
import Modal from 'react-modal';
import "./CourseContent.css";
import DocumentPreview from './DocumentPreview';

Modal.setAppElement('#root');

const CourseContent = ({ content }) => {
    const [openAll, setOpenAll] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentMedia, setCurrentMedia] = useState({ url: "", type: "" });

    const toggleAllSections = () => setOpenAll(!openAll);

    const openModal = (content, url, type) => {
        setCurrentMedia({ content, url, type });
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setCurrentMedia({ content: "", url: "", type: "" });
    };

    // Prevent right-click context menu on modal content
    const handleRightClick = (e) => {
        e.preventDefault();
    };

    // Group content into main categories and subcategories
    const groupedContent = content.reduce((acc, item) => {
        if (item.parent === null) {
            acc[item.id] = { ...item, subcategories: [] };
        } else {
            if (!acc[item.parent.id]) {
                acc[item.parent.id] = { ...item.parent, subcategories: [] };
            }
            acc[item.parent.id].subcategories.push(item);
        }
        return acc;
    }, {});

    return (
        <div className="course-content-container" 
        onContextMenu={handleRightClick}>
            <h2>Course Content</h2>
            <div className="course-content-header">
                <span>{Object.keys(groupedContent).length} main categories • {content.reduce((total, category) => total + category.lessons.length, 0)} lessons</span>
                <button onClick={toggleAllSections}>
                    {openAll ? "Collapse all sections" : "Expand all sections"}
                </button>
            </div>

            {Object.values(groupedContent).map((category, index) => (
                <Section key={index} category={category} isOpen={openAll} openModal={openModal} />
            ))}

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="media-modal"
                overlayClassName="media-modal-overlay"
                shouldCloseOnOverlayClick={false}
            >
                
                    <button className="modal-close-button" onClick={closeModal}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    {currentMedia.type === "video" ? (
                        <ReactPlayer
                            url={currentMedia.content || currentMedia.url}
                            controls
                            playing
                            width="100%"
                            height="100%"
                            config={{ file: { attributes: { controlsList: 'nodownload', disablePictureInPicture: true } } }}
                        />
                    ) : currentMedia.type === "document" ? (
                        <DocumentPreview file={currentMedia.content || currentMedia.url} />
                    ) : null}
            </Modal>
        </div>
    );
};

const Section = ({ category, isOpen: parentIsOpen, openModal }) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(parentIsOpen);
    }, [parentIsOpen]);

    return (
        <div className="section-panel">
            <div className="section-header" onClick={() => setIsOpen(!isOpen)}>
                <h3>
                    {category.name}
                    <span>{category.lessons.length + category.subcategories.reduce((total, sub) => total + sub.lessons.length, 0)} lessons</span>
                </h3>
                <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
            </div>
            {isOpen && (
                <div className="section-content">
                    {/* Render lessons directly under the main category */}
                    {category.lessons.length > 0 && (
                        <ul>
                            {category.lessons.map((lesson, lessonIndex) => (
                                <Lesson key={lessonIndex} lesson={lesson} lessonNumber={lessonIndex + 1} openModal={openModal} />
                            ))}
                        </ul>
                    )}
                    {/* Render subcategories */}
                    {category.subcategories.map((subcategory, subIndex) => (
                        <SubSection key={subIndex} subcategory={subcategory} openModal={openModal} />
                    ))}
                </div>
            )}
        </div>
    );
};

const SubSection = ({ subcategory, openModal }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="subsection-panel">
            <div className="subsection-header" onClick={() => setIsOpen(!isOpen)}>
                <h4>
                    {subcategory.name}
                    <span>{subcategory.lessons.length} lessons</span>
                </h4>
                <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
            </div>
            {isOpen && (
                <div className="subsection-content">
                    <ul>
                        {subcategory.lessons.map((lesson, lessonIndex) => (
                            <Lesson key={lessonIndex} lesson={lesson} lessonNumber={lessonIndex + 1} openModal={openModal} />
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

const Lesson = ({ lesson, lessonNumber, openModal }) => {
    return (
        <li className="lesson-item">
            {lesson.materials.length > 0 ? (
                lesson.materials.map((material, materialIndex) => (
                    <div key={materialIndex} className="material-item">
                        <FontAwesomeIcon icon={material.type === "video" ? faVideo : faFileAlt} />
                        <button onClick={() => openModal(material.content, material.content_url, material.type)}>
                            Lesson {lessonNumber} - {lesson.title} - {material.title}
                        </button>
                        <span>{material.duration}</span>
                        <button className="preview-button" onClick={() => openModal(material.content, material.content_url, material.type)}>Preview</button>
                    </div>
                ))
            ) : (
                <div className="material-item">
                    <FontAwesomeIcon icon={faFileAlt} />
                    <span>Lesson {lessonNumber} - {lesson.title}</span>
                </div>
            )}
        </li>
    );
};

export default CourseContent;